<script setup lang="ts">

import QCardHeaderSection from '~/components/ui/QCardHeaderSection.vue'
import {useAuthStore} from '~/store/authStore'

const userStore = useAuthStore()
const localePath = useLocalePath()
</script>

<template>
  <QCard v-if="userStore.user?.ongoing_tournament">
    <QCardHeaderSection
      :title="`🎯 ${$t('Your ongoing tournament')}`"
    />
    <TournamentCard :tournament="userStore.user?.ongoing_tournament" />
    <QCardActions align="right">
      <QBtn
        :to="localePath('/profile/my-tournaments')"
        color="primary"
        size="sm"
      >
        {{ $t('View all my tournaments') }}
      </QBtn>
    </QCardActions>
  </QCard>
</template>

<style scoped>

</style>
