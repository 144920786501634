<script setup lang="ts">
import RecentTournaments from '~/components/home/RecentTournaments.vue'
import OngoingTournaments from '~/components/home/OngoingTournaments.vue'
import StartingSoonTournaments from '~/components/home/StartingSoonTournaments.vue'
import refreshAll from '~/services/refreshHelper'
import UpcomingTournamentCard from '~/components/tournament/UpcomingTournamentCard.vue'
import OngoingTournamentCard from '~/components/tournament/OngoingTournamentCard.vue'
import {useAuthStore} from '~/store/authStore'

const {t} = useI18n()

const userStore = useAuthStore()
await userStore.getUser()

useSeoMeta({
  title: t('Match center'),
})

const localizePath = useLocalePath()

</script>

<template>
  <QPage padding>
    <QCard v-if="!userStore.user" class="mb-4">
      <QCardSection>
        <div class="text-h6">{{ $t('Welcome to DartsSpace') }}</div>
        <div class="q-mt-sm">
          {{ $t('Create an account or login to start signing up for tournaments and play online!') }}
        </div>
      </QCardSection>

      <QCardActions align="right">
        <QBtn color="green" :to="localizePath('/auth/register')">{{ $t('Create an account') }}</QBtn>
        <QBtn color="primary" :to="localizePath('/auth/login')">{{ $t('Login') }}</QBtn>
      </QCardActions>
    </QCard>
    <QPullToRefresh @refresh="refreshAll">
      <div class="grid xl:grid-cols-2 gap-4 items-start">
        <div class="max-w-full overflow-hidden xl:col-span-2 ">
          <Adsbygoogle
            :hide-unfilled="true"
            style="height: 224px; min-width: 100px; max-width: 100%"
            class="mx-auto"/>
        </div>
        <div
          v-if="userStore.user?.next_tournament || userStore.user?.ongoing_tournament"
          class="grid xl:col-span-2 gap-4"
          :class="{
            'grid-cols-1': userStore.user?.next_tournament || userStore.user?.ongoing_tournament,
            'md:!grid-cols-2': userStore.user?.ongoing_tournament && userStore.user?.next_tournament,
          }"
        >
          <OngoingTournamentCard/>
          <UpcomingTournamentCard/>
        </div>
        <div class="flex flex-col gap-4">
          <OngoingTournaments/>
          <RecentTournaments/>
        </div>
        <StartingSoonTournaments/>
      </div>
    </QPullToRefresh>
  </QPage>
</template>
