<script setup lang="ts">
import EmptyListMessage from '~/components/EmptyListMessage.vue'
import type {PaginatedList, Tournament} from '~/types'
import QCardHeaderSection from '~/components/ui/QCardHeaderSection.vue'

const page = ref(1)

const {data} = await useAuthFetch<PaginatedList<Tournament>>('/tournaments', {
  params: {
    filter: 'future',
    dartsspace_only: true,
    page: page,
    per_page: 10,
  }
})
</script>

<template>
  <QCard v-if="data && data.meta">
    <QCardHeaderSection
      :title="$t('Starting soon')"
      :description="$t('There are {phAmount} upcoming tournaments', {phAmount: data ? data.meta.total : '0'})"
    />
    <template v-if="data">
      <QList>
        <TournamentCard v-for="tournament in data.data" :key="tournament.id" :tournament="tournament" />
      </QList>
      <QCardSection>
        <template v-if="data.meta.total === 0">
          <empty-list-message :message="$t('No tournaments found')" />
        </template>
        <QPagination
          v-if="data.meta.total > data.meta.per_page"
          v-model="page"
          direction-links
          :max-pages="6"
          :max="data.meta.total / data.meta.per_page"
        />
      </QCardSection>
    </template>
  </QCard>
</template>
